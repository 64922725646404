#main-nav {
  border-radius: 0;

  @media screen and (max-width: 768px){
    .navbar-inner {
      background: #fff !important;
      padding: 5px;
    }
    .navbar-brand{
      padding: 0 5px;
      img {
        max-height: 50px;
      }
    }
    .navbar-collapse {
      background: $secondary-color;
    }
  }

  .navbar-inner {
    background: $primary-color;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .navbar-collapse {
    text-align: center;
  }

  .navbar-nav {
    li a {
      font-size: 16px;
      font-weight: 500;
      line-height: 30px;
      color: #fff;
      padding-top: 0;
      padding-bottom: 0;
      &:focus {
        color: #fff;
        background: none;
        outline: none;
      }
    }
  }

  @media (min-width: 768px) {
    .navbar-brand{
      display: none;
    }
    .navbar-nav {
      float:none !important;
      margin:0 auto;
      display: block;
      text-align: center;
      li {
        display: inline-block;
        float:none;
      }
      ul.dropdown-menu li {
        display: block;
      }
    }
  }

  .navbar-nav li a:hover, .navbar-nav li.active a {
    color: $secondary-color;
    background: none;
  }

  .btn-cta {
    border-radius: 10px !important;
    color: #fff!important;
    border: $blue-grey 1px solid;

    &:hover{ 
      background: transparent;
      color: #fff !important;
    }
  }

  li.dropdown:focus, li.dropdown:active, li.dropdown:hover, .nav .open>a  {
    background: transparent;
    color: #fff !important;
  }

  .navbar-toggle {
    background: $primary-color;
    .icon-bar {
      background: #fff;
    }
  }

  @media screen and (min-width: 768px) {
    ul.dropdown-menu li a{
      color: $blue-grey;
      text-transform: none;
    }
  }
}

.navbar {
  margin: 0 !important;
  -webkit-transition: all 0.4s ease;
   transition: all 0.4s ease;
}