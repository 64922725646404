.no-margin-bottom {
  margin-bottom: 0 !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-top-bottom {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

div.padding {
  padding-top: 100px;
  padding-bottom: 100px;
  h2 {
    margin-top: 0;
    color: $primary-color;
    margin-bottom: 20px;
  }
  &-large {
    padding-top: 150px;
    padding-bottom: 150px;
  }
  &-small {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  &-xsmall {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.padding-top {
  padding-top: 100px;
  &-small {
    padding-top: 50px;
  }
  &-xsmall {
    margin-top: 20px;
  }
}

.padding-bottom {
  padding-bottom: 100px;
  &-small {
    margin-bottom: 50px;
  }
  &-xsmall {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .padding-large, .padding {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}