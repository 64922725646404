@media screen and (min-width: 768px) and (max-width: 800px){
  .container {
  width: 768px;
  }
}

@media screen and (min-width: 768px) and (max-width: 899px){
  .container {
  width: 800px !important;
  }

  .navbar-header, a.navbar-brand {
    width: 100%;
  }

  .navbar {
    .navbar-nav {
      display: inline-block;
      float: none !important;
      vertical-align: top;
    }

    .navbar-collapse {
      text-align: center;
    }

    .img-responsive {
      margin: 0 auto;
    }
  }
}

@media screen and (min-width: 900px) and (max-width: 1000px){
  .container {
    width: 900px !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1000px){
  #main-nav .nav>li>a, .btn-book a {
    padding: 0 10px ;
  }
}

@media screen and (max-width: 1200px){
  #main-nav .navbar-left {
  margin-top: 5px;
  }

  .top-left a {
  font-size: 18px;
  }

  .top-left {
  margin-top:6px;
  }
}