.how-it-works {
  .circle {
    display: inline-block;
    text-align: center;
    margin: 0 auto;
    width: 150px;
    height: 150px;
    background: white;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    color: #fff;
    svg {
      fill: $secondary-color;
      padding: 40px;
      width: 100%;
    }
  }
}