.top-nav {
  color: #ddd;
  background-color: #ffffffb5;
  border-bottom: 1px solid #D8D8D8;
  padding: 10px 0;
  width: 100%;

  .booking-tool {
    padding: 0;
  }

  .bmg-ibw {
    .bmg-ibw-input, .bmg-ibw-btn {
      margin-top: 0;
    }
  }

  .brand-logo {
    padding: 3px 0;
    height: 74px;
  }

  p {
    font-weight: 500;
    color: #333333;
    margin-bottom: 0;
  }

  a:link {
    color: #333333;
  }
}

.fixed-bar {
  display: none;
}

@-webkit-keyframes menuIn {
  0% {top: -50px}
  100% {top: 0}
}

@keyframes menuIn {
  0% {top: -50px}
  100% {top: 0}
}

.fixed-bar.affix, nav.navbar.shrink {
  display: block;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #ffffffb5;
  color: #fff;
  z-index:10;
  -moz-box-shadow: 0 1px 2px #ebebeb;
  -webkit-box-shadow: 0 1px 2px #ebebeb;
  box-shadow: 0 5px 5px rgba(0,0,0,.15);
  padding: 6px 0;
  border-radius:0;
  border-bottom: 1px solid #D8D8D8;
  -webkit-animation: .25s menuIn ease; 
  animation: .25s menuIn ease;

  a {
    font-size: 14px;
    line-height: 1;
  }

  .bmg-ibw-input, .bmg-ibw-btn, .branchlist {
    height: 46px;
  }

  .bmg-ibw-btn{
    background: $primary-color;
  }

  .unknownregcontainer{
    margin-top: 0;
  }

  .bmg-ibw {
    display: block;
  }
}

@media (max-width:992px){
  .top-nav {
    padding: 0;
    text-align: center;
  }

  .fixed-bar.affix {
    .brand-logo, p {
      display: none;
    }
  }
}