.booknow, .input-vrm {
  width: 100%;
  margin-bottom: 3px;
}

.input-vrm {
  background-image: url(https://bookmygarage.com/static/images/gb.d599630299f6.png);
  background-repeat: no-repeat;
  background-position: center left;
  padding: 5px 0 5px 30px;
  height: 52px;
  font-weight: 700;
  font-size: 25px;
  text-transform: uppercase;
  background-color: #f3d41b;
  border: 1px solid #f3d41b;
  color: #1f1f1f;
  border-radius: 3px
}

.unknownregcontainer {
  text-align: left;
  margin-top: 3px;
}

.booknow {
  padding: 12px !important;
  font-weight: 500;
  font-size: 16px
}

.booking-tool {
  padding-top: 10px;
  
  h2 {
    margin-top: 0;
    font-size: 27px;
    line-height: 27px;
    font-weight: 500;
    text-shadow: none;
    margin-bottom: 10px
  }

  p {
    text-shadow: none;
    font-size: 15px
  }

  .branchlist {
    height: 52px;
  }

  .booknow {
    height: 52px;
  }
  
  .btn {
    display: inline-block;
    margin-bottom: 0;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.bg-primary .booknow {
  background: $secondary-color;
}

::-webkit-input-placeholder {
  font-weight: 400;
  font-size: 15px;
  top: -4px;
  position: relative;
  text-transform: lowercase;
  vertical-align: middle
}

:-ms-input-placeholder {
  font-weight: 400;
  font-size: 15px;
  top: -4px;
  position: relative;
  text-transform: lowercase;
  vertical-align: middle
}

::-moz-placeholder {
  font-weight: 400;
  font-size: 15px;
  top: -4px;
  position: relative;
  text-transform: lowercase;
  vertical-align: middle
}

:-moz-placeholder {
  font-weight: 400;
  font-size: 15px;
  top: -4px;
  position: relative;
  text-transform: lowercase;
  vertical-align: middle
}