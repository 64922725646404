@import '_variables';

#product-page {
  .brand-banner {
    background: #fff;

    img.logo {
      max-width:100%;

      @media (max-width: 1217px) {
        width:100%;
      }
    }
  
    h3 {
      font-size: 18px;
      margin: unset;
    }

    h3,
    p {
      color: #000;
      text-align: center;
    }
  }

  .brand-banner {
    border-bottom: 3px solid $primary-color;
  }

  .header {
    .row
    {
      display: flex;
      flex-direction: row;
      justify-content: center;

      .col-xs-12
      {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    .header-btn-ctn {
      display: flex;

      p {
        margin: 10px;
      }

      a {
        margin: auto 10px;
        background: $primary-color;
        padding: 10px;
        border-radius: 10px;

        p {
          margin: unset;
          color: #fff;
        }
      }
    }
  }

  .bmg-review-widget .review-widget {
    margin: auto;
    display: block;

    #large {
      background: rgba(41,59,78,0.75);
      border: none;

      .header .header-text {
        color: #fff;
      }
    }
  }

  .booking-tool-pp {
    h2,
    h3 {
      color: #fff;
      text-shadow: 2px 2px #000;
    }

    .bg-primary {
      padding: 20px;
      
      .btn-submit {
        background: #fff;
        color: #000;
      }
    }
  }

  main {
    background-color: #fff;

    p {
      color: #000;
    }
  }

  .main-content {
    padding: 30px;

    h1 {
      color: #000;
      text-align: center;
    }
  
    .fa-check-circle {
      color: $primary-color;
    }
  
    .checklist {
      font-size: 25px;
      text-align: center;
    }
  }

  header,
  #map {
    border-bottom: 4px solid $primary-color;
  }
  
  #map {
    border-top: 4px solid $primary-color;
  }

  div.booking-bottom {
    border-top: 4px solid $primary-color;

    a p.booking-btn {
      background: $primary-color;
      color: #fff;
    }
  }

  .booking-interval,
  .booking-bottom {
    padding: 20px 0 !important;

    h3 {
      font-size: 30px;
      text-align: center;
      margin: auto;
    }

    .booking-btn {
      background: #fff;
      color: #000;
      font-size: 30px;
      text-align: center;
      margin: auto;
      padding: 10px;
      border-radius: 20px;
      display: table;
      font-weight: 600;
    }
  }

  .booking-bottom h3 {
    color: #000;
  }

  .booking-interval h3 {
    color: #fff;
  }

  .image-section {
    padding: 20px 0 !important;

    .img-circle {
      background: rgba(106,106,106,0.5);
      border-radius: 50%;
      padding: 30px;
      height: 250px;
      width: 75%;
      margin: auto;
    }

    .imgsect-text {
      background: #161616;
      margin-top: 20px;
      border-radius: 20px;
    }
    
    h3 {
      font-size: 30px;
      text-align: center;
      margin: auto;
      text-shadow: 2px 2px #000;
    }

    p {
      color: #fff;
      text-align: center;
      font-size: 20px;
    }
  }

  .booking-bottom .booking-btn,
  .booking-bottom h3 {
    margin: 10px auto;
  }

  .vertalign {
    display: flex;
    flex-direction: row;
    justify-content: center;
  
    .col-xs-12
    {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    img {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .fixed-bar.affix {
    background: #fff;

    a,
    p {
      color: #000;
    }

    h3 {
      margin: 0;

      @media (max-width:1142px) {
        font-size: 15px;
      }
    }

    .brand-logo {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  #product-page {
    .brand-banner {
      display: block;
      margin: 0;

      img.logo {
        margin: 10px auto;
      }
    }

    .header .row {
      display: block;
      flex-direction: unset;
      justify-content: unset;
    }
  }
}

@media screen and (max-width: 991px) {
  .bmg-review-widget .review-widget #large {
    margin-top: 20px;
  }
}