@import '_variables';

// Global

body{
  font-size: 16px;
  line-height: 1.6 !important;
  font-family: 'Libre Franklin', sans-serif !important;
  overflow-x: hidden;

  a:link, a:visited  {
    color:$blue-grey;
  }
  
  a:hover  {
    color:$blue-grey;
    text-decoration: none;
  }

  h2 {
    font-weight: bold;
  }
  
  h3 {
    color: $primary-color;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1px;
  }

  h6 {
    color: #62727b;
    text-transform: uppercase;
    font-size: 0.875em;
  }

  @media only screen and (max-width: 991px) {
    .container-fluid {
      margin: 0 10px;
    }

    &#product-page .container-fluid {
      margin: auto;
    }
  }
}

.container-fluid {
  padding: 0;
}

.top-banner{
  background: $primary-color;
  max-height: 35px;

  p {
    padding: 7px 10px;
    color: #fff;
    text-align: right;
    font-size: 14px;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: -35px;
    border-top: 35px solid $primary-color;
    border-left: 50px solid $secondary-color;
  }
}

.brand-banner {
  padding: 10px 0 !important;
  
  img.logo{
    height: 75px;

    @media screen and (max-width: 991px){
      height: 65px !important;
      margin: 0 auto;

    }

    @media screen and (min-width: 400px) and (max-width: 767px){
      margin: 5px 0 0 10px;
    } 

    @media screen and (max-width:380px){
      height: 33px !important;
      margin-left: 5px;
      margin-top:10px;
    }
  }
  @media screen and (max-width: 768px){ 
    display: none;
  }

  @media screen and (min-width: 400px) and (max-width: 767px){
    margin: 5px 0 0 10px;
  }  
}

.bg-gray {
  background: #f6f6f6;
}

.bg-dkgrey{
  background: darken($primary-color, 20);
  color: #e6e6e6;
  h2 {
    color: #e6e6e6;
  }
  h3 {
    color: #e6e6e6;
  }
  .btn-ghost {
    border: #fff 1px solid;
    color: #e6e6e6;
  }
}

.bg-primary {
  background-color: $primary-color;

  a:link, a:visited {
    color: #e6e6e6;
  }

  h2{
    color:#fff !important;
    font-size: 26px;
    margin-top: 10px;
  }

  .btn-cta {
    background: #fff;
    color: #fff;
  }
}

  
@media only screen and (min-width: 991px) {
  .vertical-align {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
}

// Icons

.fa-star {
  color: #FAA632;
}

.fa-check {
  color: $secondary-color;
}

// Buttons
.btn {
  padding: 0 2.25rem;
  height: 50px;
  font-size: 16px;
  
  &-submit {
    background-color: $primary-color;
    color:#fff;
  }

  &-red {
    background-color: $secondary-color;
    color:#fff;
    line-height: 50px;
  }

  &-cta {
    background: #fff;
    color: #fff !important;
    text-transform: uppercase;
    font-weight: 500;

    &:hover {
      background: $primary-color;
      color: #fff;
    }
  }

  &-ghost {
    background: transparent;
    border: 1px $blue-grey solid;
    color: $blue-grey;
    border-radius: 25px;
    padding-top:13px;

    &:hover {
      background: $primary-color;
      color: #fff;
    }
  }

  &-flat {
    background: transparent;
    &:hover, :link {
      background: $blue-grey;
      color: #fff;
    }
  }
}

// Map

#map {
  width: 100%;
  height: 350px;
  border:none;
  font-size: 16px;
}

// List styles

ul.ticks {
  list-style-type: none;
  padding-left: 0;
  -moz-column-count: 3;
  -moz-column-gap: 20px;
  -webkit-column-count: 3;
  -webkit-column-gap: 20px;
  column-count: 3;
  column-gap: 20px;

  li {
    padding: 6px 0;

    &:before {
      content: "\f00c";
      font-family: FontAwesome;
      color: $secondary-color;
      margin: 0 8px;
    }
  }
}

main {
  background-color: #000;

  p {
    color: #fff;
  }

  .how-it-works p,
  .moderated-text {
    color: #333
  }
}

.vertalign.row
{
  display: flex;
  flex-direction: row;
  justify-content: center;

  .form-group
  {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .col-xs-12
  {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media (min-width: 991px)
{
  .rightimage
  {
    float:right;
  }

  .leftimage
  {
    float: left;
  }
}

@media (max-width: 991px)
{
  img.small-image
  {
    margin: 10px auto;
    display: block;
    max-width: 75%;
  }
}

.small-image
{
  max-width: 300px;
  border-radius: 20px;
  margin: 10px;
}

#product-page {
  #cambeltpage,
  #remappingpage {
    .bg-primary .img-circle {
      width: 75%;
      margin: 10px auto;

      img {
        border-radius: 20px;
      }
    }

    .booking-interval {
      border-bottom: 4px solid #fff;
    }
  }

  #remappingpage {
    #reviews {
      border-top: 4px solid $primary-color;
    }

    #triangle-topleft {
      width: 0;
      height: 0;
      border-top: 100px solid gray;
      border-right: 100px solid transparent;
    }

    #triangle-bottom-right {
      width: 0;
      height: 0;
      border-bottom: 100px solid gray;
      border-left: 100px solid transparent;
    }

    #remappingbottomsection {
      border-bottom: 4px solid #fff;
      
      p {
        font-size: 34px;
        text-align: center;
        padding: 0 20px 0 10px;
      }
    }
  }

  #cambelt-header,
  #remapping-header,
  #dpf-header {
    .brand-banner {
      background: $primary-color;

      h3,
      p {
        color: #fff;
      }

      .header-btn-ctn a {
        background: #fff;

        p {
          color: $primary-color;
        }
      }
    }
  }

  .bg-primary p {
    color: #fff;
  }

  #cambelt-header,
  #dpf-header {
    .hero-banner {
      .header-offer {
        @media (max-width: 991px) {
          display: none;
        }
      }

      h2 {
        color: #fff;
        font-size: 30px;
        text-shadow: 2px 2px #000;

        span {
          font-size: 40px;  
        }
      }
    }
  }

  #remapping-header {
    .hero-banner {
      .header-offer {
        @media (max-width: 991px) {
          display: none;
        }
      }

      h2 {
        color: #fff;
        font-size: 60px;
        text-shadow: 2px 2px #000;

        span {
          font-size: 80px;  
        }
      }

      .booking-tool-pp .bg-primary {
        background: rgba($primary-color, 0.8);
      }
    }
  }

  #dpf-header {
    .hero-banner 
    {
      h2 {
        font-size: 50px;
      }

      h3 {
        color: #fff;
        text-shadow: 2px 2px #000;
        font-size: 30px;
      }
    }
  }

  #dpf-bottom {
    border-top: 4px solid $primary-color;

    h2,
    h3 {
      text-align: center;
    }

    .booking-tool-pp .bg-primary {
      width: 75%;
      margin: auto;
      display: block;
      border-radius: 20px;
    }

    #reviews h2 {
      color: #000;
    }
  }

  .carousel-control.right,
  .carousel-control.left {
    background: unset;
  }

  .carousel-indicators {
    border-color: #000;

    li {
      background: #000;
    }
  }

  #reviews {
    blockquote {
      border-left: none;
      width: 75%;
      margin:auto;
      display: block;
    }

    .carousel-indicators {
      position: unset;
      left: unset;
      bottom: unset;
      margin: auto;
    }
  }
}

@media (max-width: 991px) {
  .hero-background {
    background-image: none !important;
    background: #000;

    div.padding-large {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }
  .image-section {
    background-image: none !important;
  }
}

#product-page #remappingpage div.booking-bottom {
  h3 {
    color: #fff;
  }

  a p.booking-btn {
    background: #fff;
    color: $primary-color;
  }
}

.tyre-form {
  .form-group label {
    display:grid;
    margin:auto;

    input {
      color: #000;
    }
  }

  .submitbutton {
    color: #000;
    border: 2px solid #000;
    border-radius: 10px;
    margin: auto;
    display: block;
  }

  .consentcheck,
  input[type=checkbox] {
    margin: 10px auto;
    display: block;
  }

  p {
    text-align: center;
  }
}

.tyreicon {
  border-radius: 30px;
  border: 2px solid #000;
}