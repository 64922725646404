@import "_fonts";
@import "_variables";
@import "_helpers";
@import "_layout";
@import "_footer";
@import "_responsive";

@import "pages/_index";

@import "sections/_navbar";
@import "sections/_bookingwidget";
@import "sections/_howitworks";
@import "sections/_fixedbar";

@import "fontawesome/fontawesome.scss";
@import "fontawesome/solid.scss";
@import "fontawesome/brands.scss";

@import "_product-page.scss";