@import '_variables';

.footer {
  background: #1f1f1f;

  a:link, a:visited, p, h2{
    color: #eee;
  }

  a:hover{
    color: #fff;
  }
}

.social {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  float: left;
  padding-top: 10px;
  li {
    display: inline-block;
    margin: 0 2px;
    width: 38px;
    height: 38px;
    a {
      display: block;
			text-decoration: none;
			font-size: 16px;
			color: #fff;
			line-height: 34px;
      text-align: center;
      border-radius: 50%;
			border: 1px solid #fff;
			transition: all 0.3s ease-out;
			-moz-transition: all 0.3s ease-out;
			-webkit-transition: all 0.3s ease-out;
			-o-transition: all 0.3s ease-out;
      i {
        line-height: inherit;
      }
    }
  }
}


.copyright {
  background: #1b1b1b;
  
  p, a:link, a:visited {
    padding-top: 10px;
    color: rgba(225, 225, 225, 0.6);
    font-size: 14px;
  }
}

@media (min-width:750px){
  .location {
      margin-left: 12.499999995%;
  }
}