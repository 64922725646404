@import '_variables';

.hero-background {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero-banner {
  background: rgba($primary-color, 0.2);

  h1 {
    margin-top:0;
    font-weight: 800;
    color: #fff;
    text-shadow: 2px 2px #000;
  }

  p {
    color: #fff;
    text-shadow: 2px 2px #000;
    font-weight: 500;
    font-size: 18px;
    @media (max-width:767px){
      font-size: 16px;
      line-height: 1.3;
    }
  }

  .card {
    padding: 20px;
    background: $primary-color;
    position: relative;
    border-radius: 2px;
    margin-bottom: 15px;
    line-height: 1.3;
    color: #ececec;

    h2 {
      margin-top: 0;
      font-size: 22px;
    }

    p, a {
      font-size: 16px;
      color: #ececec;
    }

    .btn-submit {
      background-color: $secondary-color;
      height: 40px;
    }

    .bmg-ibw-unknown {
      font-size: 14px;
    }

    .booknow {
      background: $secondary-color;
      color: #fff;

      &:hover {
        background: $primary-color;
      }
    }

    @media (max-width:767px){
      padding: 15px;
      
      .branchlist {
        margin: 10px 0;
      }
    }
  }
}

//Reviews
.customer-reviews{
  .card {
    background: #fff;
    padding: 15px;
    color: #222222;
    border-radius: 3px;
  }

  p {
    font-size: 15px !important;
    line-height: 1.5;
  }

  .rating {
    display: block;
  }

  .moderated-text {
    height: 90px;
    overflow: hidden;
    display: block;
    position: relative;
  }

  .elipsis {
    width: 100%;
    display: block;
    height: 33px;
    position: absolute;
    left: 0;
    top: 55px;
    background: linear-gradient(rgba(0,0,0,0), rgba(255,255,255,1));
    background-image: -webkit-gradient(linear,left top,left bottom,from(rgba(255,255,255,0)),to(white));
    background-image: -webkit-linear-gradient(top,rgba(255,255,255,0),#fff);
    background-image: -moz-linear-gradient(top,rgba(255,255,255,0),#fff);
    background-image: -ms-linear-gradient(top,rgba(255,255,255,0),#fff);
    background-image: -o-linear-gradient(top,rgba(255,255,255,0),#fff);
  }

  h3{
    margin: 0;
    padding-bottom: 0;
    color: $primary-color;
    text-transform: none;
  }

  .recommend{
    color: #3f9543;
    display: block;
    font-weight: 700;
  }

  p.header-score{
    text-align: center;
  }
}

p.header-score {
  color: #fff;
}